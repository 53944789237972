$grey-dim: #f8f8f8;
$black-dim: #0f1215;
$grey-light-dim: #e9eaed;
$grey-40: #eff0f0;
$grey-dim-100: #e8e9ec;
$grey-dim-200: #dcdde0;
$grey-medium-400: #999;
$blue-250: #0097cf;
$grey-dark-dim: #333;
$white-dim: #edf9fd;
$grey-75: #e3e4e6;
$grey-80: #ccc;
$grey-150: #c0c3ca;
$blue-ffb: #002e85;
$blue-ffb-light: #6682B6;

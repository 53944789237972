@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.social-sharing {
  padding: rem-calc(40 0);

  a {
    background: $white;
    border: rem-calc(1) solid $grey-200;
    border-radius: 50%;
    color: $grey-500;
    margin: rem-calc(0 12);

    &:hover,
    &:focus {
      background: $grey-200;
    }
  }
}

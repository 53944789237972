@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

$staticPrefix: '/static/' !default;
$no-video: '#{$staticPrefix}fil_site_assets/images/no-video.png' !default;

.fil-video {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .video-empty {
    .kv-empty {
      background: url($no-video) no-repeat 50%;
      padding: 0;
      cursor: not-allowed;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100% !important;
    }
  }

  .video-player-container {
    overflow-y: hidden;

    &.youtube,
    &.qumu {
      position: relative;
      height: 100%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint(large) {
        min-width: rem-calc(732.59);
      }
    }

    .qumu-video-iframe-wrapper,
    .youtube-video-iframe-wrapper {

      position: relative;
      width: 100%;
      padding-top: 56.25% !important; /* this will the video iframe in 16:9 ratio */

      .qumu-video-iframe,
      .youtube-video-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    p {
      margin-bottom: 0;
    }
  }
}

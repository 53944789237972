@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@import './fil-tab-accordion';

.fil-tabs {
  margin-top: rem-calc($component-padding-base);
  padding-bottom: 0;

  .tabs {
    a:focus {
      outline: none;
    }
  }

  .tabs-panel {
    > .fil-enhanced-list-wrapper {
      @include breakpoint(large) {
        @include xy-cell-size(10);
        @include xy-cell-offset(1);
      }
    }

    .fil-enhanced-list {
      border-top: none;
      border-bottom: none;
      margin: 0 !important;
      padding: 0 !important;
    }

    .fil-enhanced-list-wrapper {
      padding: 0;
    }

    .fil-text {
      .grid-container {
        padding-left: 0;
        padding-right: 0;

        .fil-text__body {
          .cell {
            h3,
            h4,
            h5,
            h6 {
              margin-top: rem-calc(36);
            }
          }
        }
      }
    }
  }

  fil-tab {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

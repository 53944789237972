@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-iss-filtered-article-list {
  .tag-filters {
    margin-top: rem-calc(27);
    margin-bottom: rem-calc(45);

    .cell-label {
      @include breakpoint(small only) {
        margin-bottom: rem-calc(24);
      }
    }

    .tag-header {
      margin-bottom: rem-calc(31);
      align-items: flex-end;

      .main-title {
        white-space: nowrap;
      }

      .article-number {
        margin-right: rem-calc(40);
      }
    }

    .tag-row {
      .chip {
        border-radius: rem-calc(21);
        background: $grey-50;
        margin-right: rem-calc(16);
        margin-bottom: rem-calc(24);
        padding: rem-calc(3 15);
        color: $grey-600;
        cursor: pointer;

        &[disabled] {
          opacity: .4;
          cursor: not-allowed;
          user-select: none;
        }

        &.checked {
          background: $blue-700;
          color: $white;
        }
      }
    }
  }

  .article-list {
    .articles {
      .cell {
        margin-bottom: rem-calc(32);

        &:empty {
          margin-bottom: 0;
        }
      }
    }
  }
}

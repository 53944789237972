@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.error-page {
  padding: rem-calc(44) 0 0 0;

  @include breakpoint(medium) {
    background-image: url('../images/error-background.jpg');
    background-position: bottom right rem-calc(-80);
    background-repeat: no-repeat;
    margin-bottom: rem-calc(-40);
    padding: rem-calc(112 0 300 0);
  }

  .error-page__title {
    font-weight: 600;
    margin: 0 0 rem-calc(18) 0;
    max-width: rem-calc(260);

    @include breakpoint(medium) {
      max-width: rem-calc(400);
    }
  }

  .error-page__subtitle {
    color: $grey-500;
    margin-bottom: rem-calc(60);
  }

  .button-group {
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }

    .button {
      margin-right: 0;
      width: 100%;

      @include breakpoint(medium) {
        margin-right: rem-calc(16);
        max-width: rem-calc(250);
      }
    }
  }
}

:lang(ja) .error-page {
  &__title {
    font-size: rem-calc(33);
    max-width: rem-calc(400);

    @include breakpoint(large) {
      font-size: rem-calc(37);
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@use '@fil-global/gds-components/scss/list/list' as fil-list;


.fil-text {

  &-container {
    padding-top: rem-calc($component-padding-base);
    padding-bottom: rem-calc($component-padding-base);
  }

  $root-class:&;

  .fil-text__body {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    word-break: break-word;

    @include fil-list.fil-unordered-list;
  }

  ol {
    word-break: break-word;

    @include fil-list.fil-ordered-list;
  }


  &.important-info {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: rem-calc($component-padding-base) !important;
    margin-bottom: rem-calc($component-padding-base);

    #{$root-class}__body > .cell {
      padding-top: rem-calc($component-padding-base);
      padding-bottom: rem-calc($component-padding-base);
      border-top: rem-calc(1) solid color(grey-100);
      border-bottom: rem-calc(1) solid color(grey-100);
    }

    + .important-info {
      border-top: none;
    }

    @at-root section.bg-grey-50 + & {
      margin-top: rem-calc($component-padding-base * 2) !important;
    }

    + section.bg-grey-50 {
      margin-top: rem-calc($component-padding-base * 2) !important;
    }
  }

  &-body-container {
    margin-bottom: 0;

    &.small {
      div,
      p,
      li {
        font-size: inherit;
      }
    }
  }

  .fil-table-wrapper {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  table {
    display: block;
    width: 100% !important;
    overflow: hidden;

    p {
      margin-bottom: 0;
    }
  }

  .table-scroll {
    width: 100%;
    overflow-x: auto;
    margin-bottom: rem-calc(40);

    table {
      display: table;
      width: 100% !important;
      margin: 0 auto;
      overflow: auto;
    }
  }
}

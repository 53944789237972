@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-cta {
  padding-top: 0;
  padding-bottom: rem-calc($component-padding-base);

  .align-center {
    justify-content: center;
  }

  .fil-button,
  fil-button {
    margin: 0 rem-calc(8);
  }

  & + .fil-cta {
    padding-top: 0;
  }
}

.bg-grey-50 + .fil-cta.bg-grey-50 {
  margin-top: rem-calc(-$component-padding-base) !important;
}

@include breakpoint(small only) {
  .fil-cta {
    .shrink {
      width: 100%;
      text-align: center;

      &:last-of-type a {
        margin-bottom: 0;
      }

      a {
        width: 100%;
        margin: 0 0 rem-calc(20) 0;
      }
    }
  }
}

@mixin author_avatar($size) {
  .author__avatar {
    position: relative;
    border-radius: 50%;
    width: rem-calc($size);
    height: rem-calc($size);
    margin-right: rem-calc(30);
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
      max-width: 200%;
    }
  }
}
@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-enhanced-list-wrapper {
  ul.paginator__nav {
    margin: rem-calc(32 0);
    user-select: none;
    text-align: center;
    background: unset;
    border: 0;
    padding: 0;

    .fil-icon {
      vertical-align: middle;
      line-height: unset;
    }

    li {
      padding: 0;
    }

    &.hide + .paginator__per-page {
      margin-top: rem-calc(32);
    }
  }

  ul {
    &.dynamic-list {
      background-color: unset;
      border: 0;
      border-radius: 0;
      padding: 0;

      @include breakpoint(small only) {
        margin: 0;
      }

      .list-header {
        padding: rem-calc(20 0 40 0);
        margin-bottom: 0;
        text-align: center;
      }

      li.list-item {
        border-top: rem-calc(1) solid $grey-50;
        margin-bottom: 0;
        padding-top: rem-calc(35);
        padding-bottom: rem-calc(35);

        @include breakpoint(small only) {
          padding-left: rem-calc(32);
        }

        &:last-child {
          border-bottom: rem-calc(1) solid $grey-50;
        }

        a {
          display: inline-block;
        }

        .tag {
          font-size: rem-calc(15);
          display: block;
        }
      }

      .paginator__nav {
        padding: rem-calc(32) 0;
        margin: 0;
        border-top: rem-calc(1) solid $grey-50;
      }
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.kv-widget,
.kv-viewer,
.kv-video,
.kv-player {
  width: 100% !important;

  a {
    width: 100% !important;
    height: inherit !important;

    .kv-thumbnail {
      position: relative;
      width: 100% !important;
      height: inherit !important;
    }
  }

  .kv-kv-player {
    width: 100% !important;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  div[class^="kv-thumbnail-holder"],
  .kv-packshot-button {
    width: 100% !important;
    height: auto !important;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.kv-play-icon {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: rem-calc(-36) !important;
  margin-left: rem-calc(-36) !important;
  z-index: 99 !important;
  width: rem-calc(72) !important;
  height: rem-calc(72) !important;
  padding: 0 !important;
}

a.kv-packshot::before,
a.kv-thumbnail-holder::before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(49, 55, 64, 0.65);
  position: absolute;
  z-index: 9;
}

.reveal {
  .kv-widget {
    margin-top: rem-calc(25);
  }

  .kv-viewer {
    width: 100% !important;
    height: rem-calc(513) !important;

    .kv-kv-player,
    div[class^="kv-thumbnail-holder"] {
      width: 100% !important;
      height: rem-calc(513) !important;
    }
  }
}

/* Styles for popout kumu video player */
.kv-overlay {
  .kv-player {
    width: 100% !important;
    height: auto !important;
    background: none !important;
    box-shadow: none;

    @include breakpoint(small only) {
      max-width: rem-calc(242);

      iframe {
        width: 100% !important;
        height: rem-calc(135) !important;
      }
    }

    @include breakpoint(medium only) {
      max-width: rem-calc(686);

      iframe {
        width: 100% !important;
        height: rem-calc(387) !important;
      }
    }

    @include breakpoint(large) {
      max-width: rem-calc(945);

      iframe {
        width: 100% !important;
        height: rem-calc(531) !important;
      }
    }

    @include breakpoint(xlarge) {
      max-width: rem-calc(1000);

      iframe {
        width: 100% !important;
        height: rem-calc(563) !important;
      }
    }
  }
}

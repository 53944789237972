.chart-and-compare-new,
.mstar-select-50,
.mstar-factsheet,
.mstar-factsheet-advised,
.mstar-chart-and-compare,
.mstar-investment-finder,
.iw_section {
  li {
    &::before {
      display: none;
    }
  }

  .ec-table__ratinglist {
    li {
      &::before {
        display: inline;
        background: none;
        position: relative;
        left: 0;
      }
    }
  }

  th {
    background-color: $grey-dim;
  }
}

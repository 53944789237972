@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.section-breadcrumb {
  margin-bottom: rem-calc(32);

  a {
    display: inline-block;
    color: $grey-800;
  }

  .fil-icon {
    &::after {
      font-size: rem-calc(12);
      color: $grey-400;
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-show-hide-wrapper {
  &.bg-grey-50 {
    padding-top: rem-calc(1) !important;
    padding-bottom: rem-calc(1) !important;
  }

  &-inner--hidden {
    margin-bottom: rem-calc(32);

    .toggler {
      color: $blue-500;
      cursor: pointer;

      .label {
        margin-right: rem-calc(12);
      }
    }

    @at-root .fil-text-container + & {
      margin-top: rem-calc(-28);
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.iss-article-teaser-grid {
  .title-keyline {
    &::after {
      background-color: $blue-500;
    }
  }

  .articles {
    &.grid-margin-x {
      .cell {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem-calc(32);
      }
    }
  }
}

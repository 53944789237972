@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.apac {
  @include breakpoint(large) {
    // Must match specificity of selector in _fil-header-jp
    .fil-header-jp .nav-bar .menu a.tier-one {
      font-size: rem-calc(15);
      margin: 0;
    }

    .main-menu {
      height: rem-calc(117);
      max-height: 100%;
    }
  }

  @include breakpoint(xxlarge) {
    // Must match specificity of selector in _fil-header-jp
    .fil-header-jp .nav-bar .menu a.tier-one {
      font-size: rem-calc(18);
      margin: inherit;
    }

    .main-menu {
      height: inherit;
    }
  }
}

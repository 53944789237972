@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@use '@fil-global/gds-components/scss/list/list' as fil-list;
@use '@fil-global/gds-components/scss/utils/utils' as utils;

.fil-table-wrapper {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .fil-text + & {
    padding-top: 0;
  }

  & + .fil-text {
    padding-top: 0;
  }

  .fil-table-inner-wrapper {
    width: 100%;
    overflow-x: auto;

    ul {
      @include fil-list.fil-unordered-list;
    }

    ol {
      @include fil-list.fil-ordered-list;
    }

    table {
      width: 100% !important;
      margin-bottom: 0;

      td {
        vertical-align: top;

        &.fil-table-row--column {
          @include breakpoint(medium down) {
            .fil-table-row-selector {
              word-break: break-word;
            }
          }
        }
      }

      .fil-icon {
        content: "";
        width: rem-calc(24);
        height: rem-calc(24);
        display: inline-block;
        vertical-align: middle;

        &.custom-tick {
          @include utils.svgIconBackground('custom-tick', 24, $green-600, 1.5);
        }

        &.close {
          background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fil-icon" fill="none" stroke="%23979da6" stroke-width="2"><path d="M4.57608699,19.576087 L19.576087,4.57608699 M19.576087,19.576087 L4.57608699,4.57608699"></path></svg>');

        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.grid-container .fil-table-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

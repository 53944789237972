.fil-tab-accordion {
  margin-left: rem-calc(-2);

  .accordion {
    list-style: none;
    margin-left: 0;
  }

  li.accordion-item {
    margin-bottom: 0;
    border: 0;

    &.is-active {
      .accordion-title {
        border-left: rem-calc(6) solid $teal-300;
        border-bottom: rem-calc(1) solid $grey-100;

        &::before {
          content: icon(chevron-u-light);
        }
      }

      .accordion-content {
        display: block;
      }
    }

    .accordion-title {
      border-top: rem-calc(1) solid $grey-100;
      border-left: rem-calc(6) solid transparent;
      display: block;
      padding: rem-calc(35 64 35 20);
      font-size: rem-calc(22);
      text-decoration: none;
      color: $grey-800;

      &::before {
        font-family: 'fidelity-icon-font';
        content: icon(chevron-d-light);
        color: $blue-500;
        position: absolute;
        width: rem-calc(28);
        height: rem-calc(28);
        line-height: rem-calc(28);
        font-size: rem-calc(20);
        text-align: center;
        margin-top: rem-calc(-2);
        padding-top: rem-calc(2);
        right: 0;
        transform-origin: center;
      }

      h5 {
        margin-bottom: 0;
      }
    }

    .accordion-content {
      padding: rem-calc(13) 0;
      display: none;
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;


h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-weight: 700;
  // margin-top: rem-calc(36);
  // margin-bottom: rem-calc(36);
}

h1,
.h1,
.heading-xl {
  @include typography(heading-xl);
}

h2,
.h2,
.heading-l {
  @include typography(heading-l);
}

h3,
.h3,
.heading-m {
  @include typography(heading-m);
}

h4,
.h4,
.heading-s {
  @include typography(heading-s);
}

h5,
.h5,
.heading-xs {
  @include typography(heading-xs);
}

h1.super,
.h1.super,
.heading-xxl {
  @include typography(heading-xxl);
}

.body-m {
  display: inline-block;
}

p,
.p,
.body-m {
  display: block;
  margin-bottom: rem-calc(23);
  //font-weight: bold;
  @include typography(body-m);

  @include breakpoint(large up) {
    margin-bottom: rem-calc(26);
  }

  &.bold {
    font-weight: 700;
  }

  &.intro,
  &.lead,
  .body-l {
    font-weight: 400;
    @include typography(body-l);
  }

  &.small,
  .body-s {
    font-weight: 400;
    @include typography(body-s);
  }

  &.xsmall,
  .body-xs {
    font-weight: 400;
    @include typography(body-xs);
  }

  &.hint {
    margin-bottom: 0;
    color: $grey-400;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////

.font-white {
  color: $font-white;
}

.text-link {
  span {
    font-size: rem-calc(14.4);
  }
}

.text-lower-case {
  text-transform: lowercase;
}

.fil-icon {
  display: inline-block;
  line-height: 1;
}

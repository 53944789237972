@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-article-list--compact {
  .link-list {
    border-radius: rem-calc(4);
    border: rem-calc(1) solid $grey-50;
    margin-bottom: 0;
    margin-left: 0;
    padding: rem-calc(30 23 30 43);

    @include breakpoint(medium) {
      padding: rem-calc(38 40 38 57);
    }

    .link-item {
      padding-left: rem-calc(28);

      a {
        display: block;
        width: 100%;
      }

      .date {
        margin-right: rem-calc(10);
      }

      &::before {
        background: $white;
        border-radius: 50%;
        border: rem-calc(1) solid $grey-50;
        color: $blue-500;
        content: icon(chevron-r-regular);
        font-family: 'fidelity-icon-font';
        font-size: rem-calc(12);
        height: auto;
        padding: rem-calc(6 9);
        top: rem-calc(-3);
        width: auto;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-article-page {
  .global-cms-iss-article {
    &.author_hidden {
      .article-main-content {
        padding-top: 0;
      }
    }

    .article-heading {
      font-size: rem-calc(40) !important;
      line-height: 1.2 !important;
      margin-bottom: rem-calc(24);

      + p {
        margin-bottom: rem-calc(24);
      }

      @include breakpoint(large) {
        font-size: rem-calc(56) !important;
      }
    }

    .article-bg-image-wrapper {
      width: 50% !important;
    }

    .article-main-content {
      .fil-text-container {
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(40);

        @include breakpoint(small only) {
          &.no-mobile-padding-top {
            padding-top: 0;
          }
        }

        .image-source {
          @include typography(body-xs);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-align: left;
        }

        p {
          word-wrap: break-word;
        }

        p > img {
          padding-top: rem-calc(40);
          padding-bottom: rem-calc(40);
          background: $white;
        }
      }

      .fil-image {
        img {
          padding-top: rem-calc(40);
          padding-bottom: rem-calc(40);
          background: $white;
        }
      }

      .article-body {
        @include breakpoint(medium down) {
          padding-bottom: 0 !important;
        }

        .sticky-img-wrapper {
          width: auto;
          margin-left: 0;
          max-width: 100%;

          @include breakpoint(medium only) {
            width: 100vw;
            margin-left: rem-calc(-32);
            max-width: inherit;
          }

          @include breakpoint(small only) {
            width: 100vw;
            margin-left: rem-calc(-20);
            max-width: inherit;
          }
        }
      }

      .sticky-floating-section {
        @include breakpoint(large) {
          position: fixed;
          top: rem-calc(40);
        }
      }

      .article-section-right-wrapper {
        position: relative;
        height: 100%;

        .fil-article-list {
          &.no-padding-top {
            @include breakpoint(large) {
              padding-top: 0;
            }
          }
        }
      }

      .right-br {
        border-right: none !important;
        padding-right: rem-calc(16) !important;
      }

      .left-br {
        padding: rem-calc(40 16) !important;

        @include breakpoint(medium down) {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }
      }

      .content-navigation {
        margin-bottom: rem-calc(32);

        &.is-mobile {
          border-top: rem-calc(1) solid $grey-100;
          border-bottom: rem-calc(1) solid $grey-100;
          padding-top: rem-calc(32);
          padding-bottom: rem-calc(8);
          margin-bottom: 0;

          ul.fil-list {
            padding-left: rem-calc(30) !important;
          }
        }
      }

      .fil-article-tags-container {
        @include breakpoint(large) {
          justify-content: flex-start;
        }

        @include breakpoint(medium down) {
          padding-bottom: rem-calc(20) !important;
          border-bottom: rem-calc(1) solid $grey-100 !important;
          margin-bottom: 0;
        }

        .article-tag {
          margin-left: rem-calc(12);
          margin-right: rem-calc(12);

          &:last-child {
            border-bottom: rem-calc(2) solid $grey-50 !important;
            padding: rem-calc(8 16) !important;
          }

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .social-sharing-wrapper {
        height: 100%;
      }

      .floating-social-sharing-container {
        padding-top: rem-calc(48);
      }

      .social-sharing {
        padding: rem-calc(40 0 0 0) !important;
        border-top: rem-calc(1) solid $grey-100;

        @include breakpoint(medium down) {
          text-align: center;
        }

        a.fil-icon {
          border: none;
          border-radius: 0;
          margin: rem-calc(0 16);
          color: $blue-500;

          &:active {
            color: $grey-800;
          }

          &:hover,
          &:focus {
            color: $grey-500;
            background: inherit;
          }
        }

        .grid-x {
          @include breakpoint(large) {
            justify-content: flex-start;
          }

          .cell:first-of-type a {
            margin-left: 0;
          }
        }

        &--vertical {
          border-top: 0;
          padding-top: rem-calc(0) !important;

          a.fil-icon {
            margin-bottom: rem-calc(32);
          }

          .separator {
            margin: 0 auto rem-calc(32) auto;
            border-top: rem-calc(1) solid $grey-100;
            width: rem-calc(24);
          }
        }
      }
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.outlook-layout-submenu {
  &__wrapper {
    position: relative;
    padding: 0;
    margin: 0;
    border-top: rem-calc(1) solid $grey-100;
    margin-bottom: rem-calc(45);

    &--bottom-border {
      position: absolute;
      width: 100%;
      height: rem-calc(1);
      left: 0;
      bottom: rem-calc(1);
      border-bottom: rem-calc(1) solid $grey-100;
      z-index: 2;
    }

    &.empty {
      display: none;
    }

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      height: 100%;
      width: rem-calc(40);
      z-index: 1;
    }

    &::before {
      left: 0;
      background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
    }

    &::after {
      right: 0;
      background-image: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }

  &__container {
    list-style-type: none;
    padding: 0 rem-calc(45);
    margin-bottom: 0;
    font-size: 0;

    @include breakpoint(small) {
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      padding: 0;
      margin: 0;
      display: inline-block;

      a {
        display: block;
        position: relative;
        color: $grey-500;
        padding: rem-calc(14);
        font-size: rem-calc(18);

        @include breakpoint(medium) {
          padding: rem-calc(24);
        }

        &:hover {
          background-color: $grey-25;
        }

        &:active {
          color: $grey-900;
          background-color: $grey-50;
        }

        &:hover,
        &:active,
        &.selected {
          text-decoration: none;

          &::after {
            display: block;
            content: "";
            width: 100%;
            height: rem-calc(3);
            bottom: 0;
            position: absolute;
            left: 0;
            background-color: $blue-600;
            z-index: 3;
          }
        }
      }

      &::before {
        display: none;
      }
    }
  }
}

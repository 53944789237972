@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-cards {
  padding-top: rem-calc($component-padding-base);
  margin-bottom: 0;

  &.bg-grey-50 {
    padding-bottom: rem-calc($component-padding-base) !important;
  }

  &.fil-bg-grey {
    background-color: $grey-50;
  }

  .card-container {
    position: relative;
  }

  // .card {
  //   position: relative;
  //   padding: rem-calc(40 0 70);
  //   margin-bottom: rem-calc($component-padding-base);
  //   border: rem-calc(1) solid $grey-100;
  //   border-radius: rem-calc(4);
  //   text-align: center;
  //   background: $white;
  //   width: 100%;

  //   .card-section {
  //     padding: rem-calc(0 30);
  //     margin-bottom: rem-calc(26);
  //   }

  //   h4 {
  //     position: relative;
  //     font-weight: bold;

  //     &.keyline {
  //       margin-bottom: rem-calc(24);
  //       padding-bottom: rem-calc(26);

  //       &::after {
  //         display: block;
  //         position: absolute;
  //         left: 50%;
  //         margin-left: rem-calc(-25);
  //         bottom: 0;
  //         content: '';
  //         height: rem-calc(3);
  //         width: rem-calc(50);
  //         background-color: $blue-500;
  //       }
  //     }
  //   }

  //   p {
  //     text-align: center;
  //     min-height: rem-calc(52);
  //     margin-bottom: 0;
  //   }

  //   .cta-section {
  //     position: absolute;
  //     display: block;
  //     left: 0;
  //     bottom: rem-calc(20);
  //     width: 100%;
  //     font-size: rem-calc(18);

  //     .arrow-right-cta {
  //       display: inline-block;
  //       margin-bottom: rem-calc(16);

  //       span {
  //         font-weight: bold;
  //         position: relative;
  //         top: rem-calc(-2);
  //       }
  //     }

  //     .button {
  //       margin-bottom: 0;
  //       padding: rem-calc(16 50);
  //       max-width: 100%;

  //       span {
  //         display: none;
  //       }
  //     }
  //   }

  //   &.button-cta-margin {
  //     padding-bottom: rem-calc(120);
  //   }

  //   &.no-border {
  //     background: none;
  //     border: none;
  //     padding: 0;
  //   }

  //   &.cta-only {
  //     padding: rem-calc(40 0 70);
  //   }

  //   &.dual-cta {
  //     padding: rem-calc(40 0 140);

  //     .button {
  //       margin-bottom: rem-calc(12);
  //     }

  //     .arrow-right-cta {
  //       margin-bottom: rem-calc(12);
  //     }
  //   }
  // }
}

@include breakpoint(small only) {
  .fil-cards {
    padding-bottom: rem-calc($card-grid-padding-bottom-mobile);

    &.bg-grey-50 {
      padding-bottom: rem-calc($card-grid-padding-bottom-grey-mobile) !important;

      +.fil-cards.bg-grey-50 {
        margin-top: rem-calc(-$card-grid-padding-bottom-grey-mobile*2) !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .card-container {
      padding-bottom: rem-calc($card-grid-padding-bottom-grey-mobile) !important;
    }

    .grid-padding-x {
      .cell {
        padding: 0;

        .card {
          margin-bottom: rem-calc($card-padding-bottom-mobile);

          h4 {
            min-height: auto;
          }
        }
      }
    }
  }
}

.apac {
  .fil-cards .card p {
    text-align: left;
  }
}

.fil-image-gallery {
  padding-top: rem-calc($component-padding-base);
  margin-bottom: 0;

  &.bg-grey-50 {
    padding-bottom: rem-calc($component-padding-base) !important;
  }

  // &.grey-True {
  //   a {
  //     .card {
  //       background-color: $white;
  //     }

  //     &:hover {
  //       .card {
  //         background-color: $grey-100;
  //       }
  //     }
  //   }
  // }

  a {
    &:hover {
      .card {
        background-color: $grey-100;
        border: rem-calc(1) solid $grey-200;

        img {
          opacity: 0.8;
        }

        h3 {
          color: $blue-500;
        }
      }
    }
  }

  .card {
    background-color: $grey-50;
    border: rem-calc(1) solid $grey-100;
    margin-bottom: rem-calc($component-padding-base);

    img {
      width: 100%;
    }

    .meta {
      position: relative;
      padding-bottom: rem-calc(20);

      h3 {
        margin-bottom: 0;
        font-size: rem-calc(26);
        color: $grey-900;
        position: absolute;
        bottom: rem-calc(15);
        left: 0;
        right: 0;
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        text-align: left;
        font-weight: bold;
      }
    }
  }

  @include breakpoint(small only) {
    .card {
      margin-bottom: rem-calc($card-padding-bottom-mobile);
    }

    padding-bottom: rem-calc($card-grid-padding-bottom-mobile);

    &.bg-grey-50 {
      padding-bottom: rem-calc($card-grid-padding-bottom-grey-mobile) !important;
    }
  }
}

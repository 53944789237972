@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.social-sharing {

  &--page {
    padding: rem-calc(0 40 36 40);

    hr {
      margin-top: 0;
      margin-bottom: rem-calc(30);
    }

    a {
      border: 0;
      border-radius: 0;
      color: $blue-500;

      &:hover,
      &:focus {
        background: $white;
      }
    }
  }

  &--article {
    fil-loader {
      margin: rem-calc(0 16);
      display: inline-block;
    }

    .separator {
      margin: rem-calc(0 16);
      height: rem-calc(24);
      width: rem-calc(1);
      border-left: rem-calc(1) solid $grey-100;
    }
  }
}

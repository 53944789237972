@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

@import 'client-option';
@import 'country-selector';

.reveal.bfob-modal {
  background-color: $grey-50;
  margin: auto;
  max-width: rem-calc(974);

  .modal__exit {
    color: $blue-500;
    font-weight: bold;
    top: rem-calc(21.5);
    right: rem-calc(21.5);

    &:hover {
      color: $grey-900;
    }
  }
}

.fil-icon-ps {
  background-image: url($products-and-services-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: rem-calc(20);
  display: inline-block;
  width: rem-calc(70);
  height: rem-calc(22);
  vertical-align: middle;

  @include breakpoint(medium down) {
    margin: rem-calc(6) auto rem-calc(10) auto !important;
  }

  @include breakpoint(large) {
    background-image: url($products-and-services);
    width: rem-calc(22);
    height: rem-calc(20);
    margin-right: rem-calc(8);
  }
}

.text-capitalize {
  text-transform: capitalize;
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-messaging {
  margin: rem-calc($component-padding-base 0);

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@use '@fil-global/gds-tokens/dist/scss/variables' as *;

@each $color in $color-keys {
  $color-value: map-get(map-get($color-tokens, $color), '500');

  .bg-#{$color} {
    background-color: $color-value;
    color: $color-grey-800;
  }

  .text-#{$color} {
    color: $color-value;
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@use '@fil-global/gds-components/scss/utils/utils' as utils;

.intl {
  // .elastic-header
  .fil-header {
    position: fixed;
    top: 0;
    transition: all 0.5s;
    width: 100%;

    &.scrollUp {
      transform: translateY(-100%);

      @include breakpoint(large) {
        transform: translateY(0);
      }
    }

    @include breakpoint(large) {
      position: relative;
    }
  }

  .global-section {
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 90;

    @include breakpoint(large) {
      position: fixed;
      z-index: 9999;
    }
  }

  main {
    margin-top: rem-calc(81);

    @include breakpoint(large) {
      margin-top: rem-calc(41);
    }
  }
}

.fil-header {
  position: relative;
  z-index: 91;
  border-bottom: rem-calc(1) solid $grey-100;

  .fil-icon {
    vertical-align: middle;

    &.fil-icon-arrow-r-light {
      margin-left: rem-calc(6);
    }
  }

  .nav-bar {
    background-color: $white;
    height: 100%;

    .logo-jp,
    .logo-kr {
      margin: 0 0 0 rem-calc(25);
    }

    .logo-jp {
      img {
        height: rem-calc(48);
        max-width: unset;
      }
    }

    .fil-icon-login {
      background: url($login-icon-black) no-repeat 50%;
      display: inline-block;
      width: rem-calc(22);
      height: rem-calc(34);
      vertical-align: middle;
      margin-right: rem-calc(8);
    }

    .fil-icon-logout {
      background: url($logout-icon-black) no-repeat 50%;
      display: inline-block;
      width: rem-calc(70);
      height: rem-calc(22);
      vertical-align: middle;
      margin: rem-calc(6) auto rem-calc(10) auto !important;
    }

    @include breakpoint(medium down) {
      &.wfb-nav {
        .logout {
          display: none !important;
        }

        .login {
          display: block !important;

          &.login-disabled {
            display: none !important;
          }
        }

        &.logged-in {
          .login {
            display: none !important;
          }

          .logout {
            display: block !important;
          }
        }
      }
    }

    &.with-additional-logo {
      & > .logo-container {
        flex: none;
        width: initial;
        padding-right: rem-calc(20);
      }

      & > .main-menu {
        flex: 1 1;
        width: auto;
      }

      @include breakpoint(medium down) {
        & > .logo-container {
          flex: 1;
        }

        & > .main-menu {
          flex: none;
          width: 100%;
        }
      }

      @include breakpoint(small down) {
        & > .logo-container {
          padding-right: 0;

          .fil-header__logo-separator {
            margin: 0 rem-calc(20);
          }
        }
      }
    }

    .menu {
      z-index: 99;

      a.tier-one {
        display: block;
        z-index: 99;
        position: relative;
        font-size: rem-calc(15);
        color: $black-dim;
        margin: 0 rem-calc(10);
        text-decoration: none;

        @include breakpoint(large only) {
          margin-left: 0;
        }

        @include breakpoint(xlarge) {
          font-size: rem-calc(18);
        }

        @include breakpoint(xxlarge) {
          padding-left: rem-calc(15);
          padding-right: rem-calc(15);
        }

        &::before {
          background-color: $blue-500;
          content: '';
          display: none;
          height: rem-calc(3);
          position: absolute;
          top: 100%;
          width: calc(100% - #{rem-calc(19)});

          @include breakpoint(xxlarge) {
            width: calc(100% - (#{rem-calc(15)} * 2) - #{rem-calc(19)});
          }
        }

        &.no-children::before {
          width: 100%;

          @include breakpoint(xxlarge) {
            width: calc(100% - (#{rem-calc(15)} * 2));
          }
        }

        &:last-of-type {
          margin-right: rem-calc(27);
        }

        span {
          font-size: rem-calc(13);
          color: $grey-200;
        }

        &.active-megamenu {
          &::before {
            display: block;
          }

          span {
            display: inline-block;
            -moz-transform: scaleY(-1);
            -o-transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
            filter: FlipV;
            -ms-filter: 'FlipV';
          }

          .fil-icon-chevron-down {
            bottom: 0;
          }

          + .megamenu {
            height: auto;
            max-height: rem-calc(999);
            overflow-y: visible;
            overflow-x: visible;
            opacity: 1;
            border-top: rem-calc(1) solid $grey-100;

            .category {
              transform: none;
              opacity: 1;

              @for $i from 1 through $tier-two-menu-max-allowed {
                &:nth-of-type(#{$i}) {
                  transition-delay: #{0.2 + 0.1 * $i}s;
                }
              }
            }

            .overview-link {
              opacity: 1;

              a {
                margin-left: rem-calc(4);

                img {
                  width: rem-calc(24);
                  position: relative;
                  left: rem-calc(-4);
                }
              }
            }

            + .backdrop {
              height: 100vh;
              background-color: rgba($black, 0.7);

              + .close-megamenu {
                display: block;
              }
            }
          }
        }

        &.ancestor {
          &::before {
            display: block;
          }
        }

        &.active-ancestor {
          &::before {
            background-color: $grey-100 !important;
          }
        }
      }

      .megamenu {
        display: block;
        opacity: 0;
        max-height: 0;
        height: 0;
        z-index: 99;
        overflow-y: hidden;
        overflow-x: hidden;
        background-color: $grey-25;
        border-top: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        transition: opacity 0.3s linear, max-height 0.8s ease;
        padding: 0 rem-calc(42);

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: rem-calc(-1);
          width: 50vw;
          //Do NOT use rem-calc here, it doesn't work inside calc()
          height: calc(100% + #{rem-calc(1)});
          background-color: $grey-25;
          border-top: rem-calc(1) solid $grey-100;
        }

        &::before {
          left: -50vw;
        }

        &::after {
          right: -50vw;
        }

        .cell .cell {
          padding: rem-calc(27 26);
        }

        .meta {
          padding: rem-calc(27 42 27 0);
          border-right: rem-calc(1) solid $grey-100;
        }

        h3 {
          font-weight: bold;
          margin-bottom: rem-calc(37);
        }

        h6 {
          font-size: rem-calc(18);
          margin-bottom: rem-calc(0);
          min-height: rem-calc(26);
        }

        hr {
          margin: rem-calc(17 0 19);
        }

        a {
          width: 100%;
          margin-bottom: rem-calc(20);
          font-size: rem-calc(18);
          display: block;

          &:focus {
            text-decoration: none;
          }
        }

        .button {
          display: inline-block;
          width: auto;
        }

        &:hover {
          + .backdrop {
            height: 100vh;
            background-color: rgba($black, 0.7);
          }
        }

        &.megamenu-closed {
          opacity: 0;
          max-height: 0;

          + .backdrop {
            background-color: rgba($black, 0);
          }
        }

        .category {
          opacity: 0;
          transform: translateY(-5%);
          transition: all 0.1s ease-in;
          transition-delay: 0s;
        }

        .tier-two-wrapper {
          position: relative;

          .tier-two {
            .category-header {
              font-weight: bold;
            }

            .tier-three-link {
              &::after {
                font-weight: bold;
                margin-left: rem-calc(6);
              }
            }

            .tier-three {
              z-index: -1;
              position: absolute;
              opacity: 0;
              background-color: $grey-50;
              top: rem-calc(-2);
              left: 0;
              min-width: 100%;
              min-height: 100%;
              transform: translateX(100vw);
              transition: all 0.3s ease-in;

              &.active {
                z-index: 99;
                opacity: 1;
                transform: translateX(0);
                transition: all 0.3s ease-out;
              }

              &::after {
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
                width: 100vw;
                height: 100%;
                background-color: $grey-50;
              }

              .cell {
                padding-bottom: rem-calc(60);
              }

              .multi-column {
                column-count: 2;
              }

              .tier-three-return {
                position: absolute;
                bottom: rem-calc(0);
                left: rem-calc(26);
              }

              .fil-icon-arrow-l-light {
                color: $blue-500;
                font-weight: bold;
                top: rem-calc(1);
                position: relative;
                margin-right: rem-calc(6);
              }
            }
          }
        }

        .cell.overview-link {
          opacity: 0;
          transition: opacity 0.3s linear;
          transition-delay: 0.3s;
          padding: 0 rem-calc(26);

          hr {
            margin: 0;
          }

          a {
            padding: rem-calc(23) 0;
            margin-bottom: 0;
          }

          .overview-icon {
            position: relative;
            margin-top: rem-calc(-2);
          }
        }

        &-banner-wrapper {
          width: rem-calc(230);
          height: rem-calc(225);
          margin-top: 0;
          padding-top: rem-calc(130);
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100%;

          &--label {
            display: flex;
            place-items: center;
            padding: rem-calc(20);
            height: rem-calc(100);
            font-size: rem-calc(16);
            background: $blue-600;

            .arrow-right-cta {
              &::after {
                background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fil-icon inherit-size" fill="none" stroke="%23ffffff" stroke-width="2" aria-hidden="true"><line class="icon__properties" x1="1" x2="22.5" y1="12" y2="12"></line><polyline class="icon__properties" points="16.001 18.999 23 12 16 5"></polyline></svg>');
                content: '';
                background-repeat: no-repeat;
                background-size: rem-calc(16 16);
                width: rem-calc(16);
                height: rem-calc(16);
                margin-left: rem-calc(5);
                display: inline-block;
              }
            }

            .link {
              line-height: rem-calc(24);
              color: $white;
              margin-bottom: 0;
              overflow: hidden;
              padding: 0;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;

              /* autoprefixer: off */
              -webkit-box-orient: vertical;

              /* autoprefixer: on */
            }
          }

          &.no-banner {
            height: rem-calc(130);
            padding-top: 0;

            a {
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      .close-megamenu {
        position: absolute;
        display: none;
        z-index: 999;
        margin-top: rem-calc(27);
        right: rem-calc(27);
        text-decoration: none;
      }

      .backdrop {
        content: '';
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        transition: background-color 0.2s ease;
        background-color: rgba($black, 0);
      }
    }
  }

  .logo-container {
    border-bottom: rem-calc(1) solid $grey-100;
    height: 100%;

    @include breakpoint(large) {
      border-bottom: 0;
    }

    @include breakpoint(small) {
      a:nth-child(3) {
        display: none !important;
      }
    }
  }

  .fil-header__logo-container {
    align-items: center;
    display: flex;
    height: rem-calc(80);

    @include breakpoint(large) {
      height: 100%;
    }
  }

  .fil-header__logo {
    flex: 0 0 auto;
    line-height: 0;

    img {
      height: rem-calc(40);
      width: auto;
      max-width: 100%;

      @include breakpoint(large) {
        height: rem-calc(48);
      }
    }
  }

  .fil-header__logo--fil {
    margin-left: rem-calc(20);

    @include breakpoint(large) {
      margin-left: rem-calc(40);

      .logo-ffb {
        margin-left: rem-calc(-40);
      }
    }
  }

  .fil-header__logo--fmr-mobile {
    // Offset to account for y descender
    margin-top: rem-calc(6);

    img {
      height: rem-calc(24);
    }
  }

  .fil-header__logo-separator {
    background-color: $grey-100;
    flex: 0 0 auto;
    height: rem-calc(40);
    margin: 0 rem-calc(40);
    width: rem-calc(1);

    @include breakpoint(large) {
      height: rem-calc(48);
      margin: 0 rem-calc(48);
    }
  }

  .fil-header__underbar {
    width: 100%;

    .fil-icon-ps {
      // Must use !important to override existing flag usage in fil-bfob/_main.scss
      margin: rem-calc(6 0) !important;
    }
  }

  // Desktop menu styling
  @include breakpoint(large) {
    height: rem-calc(96);

    .close-megamenu {
      top: 100%;
    }

    .menu {
      height: 100%;
    }

    .nav-bar {
      height: 100%;
    }

    .tier-one-wrapper {
      align-items: center;
      display: flex;
    }

    .tier-one {
      padding: 0;

      &::before {
        margin-top: rem-calc(10);
      }
    }
  }

  /* Small only */
  @include breakpoint(small only) {
    .show-for-large {
      display: none !important;
    }

    .hide-for-large {
      display: block !important;
    }
  }

  /* Medium and up */
  @include breakpoint(medium) {
    .show-for-large {
      display: none !important;
    }

    .hide-for-large {
      display: block !important;
    }
  }

  /* Medium only */
  @include breakpoint(medium only) {
    .show-for-large {
      display: none !important;
    }

    .hide-for-large {
      display: block !important;
    }
  }

  /* Large and up */
  @include breakpoint(large) {
    .show-for-large {
      display: block !important;

      &.fil-icon-chevron-down {
        display: inline-block !important;
        line-height: inherit;
        position: relative;
        left: rem-calc(2);
        bottom: rem-calc(3);
      }

      &.grid-x {
        display: flex !important;
      }
    }

    .hide-for-large {
      display: none !important;
    }
  }

  /* Large only */
  @include breakpoint(large only) {
    .show-for-large {
      display: block !important;

      &.fil-icon-chevron-down {
        display: inline-block !important;
        line-height: inherit;
        position: relative;
        left: rem-calc(2);
        bottom: rem-calc(3);
      }

      &.grid-x {
        display: flex !important;
      }
    }

    .hide-for-large {
      display: none !important;
    }
  }
}

@include breakpoint(medium down) {
  .fil-header {
    .nav-bar {
      .main-menu {
        transition: all 0.3s ease-in-out;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
      }

      .menu-toggle {
        border-bottom: rem-calc(1) solid $grey-100;
        border-left: rem-calc(1) solid $grey-100;
        cursor: pointer;
        padding: rem-calc(16 24 10);
        text-align: center;
        width: auto;

        &.is-open {
          .menu-closed {
            display: none;
          }

          .menu-open {
            display: block;
          }

          ~ .main-menu {
            max-height: rem-calc(999);
            overflow: visible;
            opacity: 1;
          }
        }

        span {
          display: block;
          width: 100%;

          + span {
            margin-top: rem-calc(-5);
            font-size: rem-calc(14);
          }

          &.fil-icon {
            margin-bottom: rem-calc(4);

            &.fil-icon-navigation-menu {
              margin-top: rem-calc(6);
              margin-bottom: rem-calc(8);
            }

            &::after {
              font-size: rem-calc(24);
            }
          }
        }

        .menu-open {
          display: none;
        }
      }

      .menu {
        .cell {
          width: 100%;

          a.tier-one {
            width: 100%;
            position: relative;
            font-size: rem-calc(18);
            margin: 0;
            padding: rem-calc(20);
            background-color: $white;
            border-bottom: rem-calc(1) solid $grey-dim-100;
            font-weight: bold;
            z-index: 9;

            &::before {
              content: '';
              background-color: $blue-500;
              position: absolute;
              display: block;
              margin-left: 0;
              left: 0;
              top: 0;
              bottom: 0;
              width: rem-calc(3);
              height: 100%;
            }

            &::after {
              content: '';
              @include utils.svgIconBackground('custom-chevron-down', 16, $blue-500, 3);
              background-position: center;
              color: $blue-500;
              position: absolute;
              right: rem-calc(20);
              width: rem-calc(24);
              height: rem-calc(24);
              transition: all 0.5s ease-in-out;
            }

            &:hover {
              + .megamenu {
                overflow-x: hidden;
                overflow-y: hidden;
                opacity: 0;
                border-top: none;
                max-height: 0;
              }
            }

            &.no-children {
              &::after {
                display: none;
              }
            }

            &.active-megamenu {
              background-color: $grey-25;
              color: $blue-500;

              + .megamenu {
                max-height: rem-calc(999);
                overflow-y: visible;
                overflow-x: visible;
                opacity: 1;
                border-top: rem-calc(1) solid $grey-100;
              }

              &::after {
                @include utils.svgIconBackground('custom-chevron-up', 16, $blue-500, 3);
              }
            }

            + .megamenu {
              .tier-two {
                h6,
                a {
                  &::before {
                    content: '';
                    background-color: $blue-500;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: rem-calc(3);
                    height: 100%;
                  }
                }

                .category-body {
                  a,
                  h6 {
                    &::before {
                      display: none;
                    }
                  }

                  .category-body-return,
                  .tier-three-return {
                    &::before {
                      background-color: $blue-500;
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }

        .megamenu {
          background-color: $grey-25;
          border-top: none;
          top: 0;
          position: relative;
          padding: 0;

          a {
            margin-bottom: 0;
            position: relative;
          }

          .cell {
            .cell {
              padding: 0;
            }
          }

          .meta {
            padding: rem-calc(10) 0;
            border-right: none;
          }

          .tier-two-wrapper {
            padding: 0;

            .tier-two {
              .category-header {
                position: relative;
                color: $blue-500;
                font-weight: normal;
                padding: rem-calc(15 20);
                border-bottom: rem-calc(1) solid $grey-100;

                .fil-icon {
                  &.fil-icon-arrow-r-light {
                    font-weight: bold;
                    margin-left: rem-calc(6);
                    position: absolute;
                    right: rem-calc(20);
                    top: 50%;
                    margin-top: rem-calc(-13);
                  }
                }
              }

              .category-body {
                background-color: $grey-25;
                z-index: -1;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                overflow-y: auto;
                overflow-x: hidden;
                transform: translateX(100vw);
                transition: all 0.2s ease-in;

                &.active {
                  z-index: 99;
                  transform: translateX(0);
                  transition: all 0.2s ease-out;
                }

                .category-header {
                  color: $grey-900;
                }

                a,
                .category-header {
                  margin: 0;
                  padding: rem-calc(20);
                  border-bottom: rem-calc(1) solid $grey-100;
                }

                .category-body-return {
                  width: 100%;
                  padding: rem-calc(20);
                  margin: 0;
                  background-color: $white;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: rem-calc(3);
                    height: 100%;
                  }
                }

                .fil-icon-arrow-l-light {
                  color: $blue-500;
                  font-weight: bold;
                  top: rem-calc(1);
                  position: relative;
                  margin-right: rem-calc(6);
                  vertical-align: top;
                }
              }

              .tier-three {
                width: 100%;
                background-color: $grey-25;

                &.active {
                  top: 0;
                  min-height: rem-calc(1000);
                  overflow-y: scroll;
                  overflow-x: hidden;
                }

                a,
                h6 {
                  width: auto;
                  padding: rem-calc(20) 0;
                  border-bottom: rem-calc(1) solid $grey-100;
                  margin: 0 rem-calc(20);
                  float: none;
                }

                .multi-column {
                  column-count: 1;
                }

                .tier-three-return {
                  position: relative;
                  left: 0;
                  width: 100%;
                  padding: rem-calc(20);
                  margin: 0;
                  background-color: $white;

                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: rem-calc(3);
                    height: 100%;
                  }
                }

                .fil-icon-arrow-l-light {
                  color: $blue-500;
                  font-weight: bold;
                  top: rem-calc(1);
                  position: relative;
                  margin-right: rem-calc(6);
                }
              }

              .tier-three-link {
                &::after {
                  font-weight: bold;
                  margin-left: rem-calc(6);
                  position: absolute;
                  right: rem-calc(20);
                }
              }
            }
          }
        }

        .close-megamenu {
          display: none !important;
        }
      }
    }
  }
}

.skip-header-link {
  position: absolute;
  left: rem-calc(-10000);
  top: auto;
  width: rem-calc(1);
  height: rem-calc(1);
  overflow: hidden;

  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-article-list {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .fil-article-list__results-count {
    margin: rem-calc(40) 0;
    width: 100%;
  }

  .paginator__nav {
    margin-bottom: 0;
    width: 100%;
  }

  .paginator__per-page {
    width: 100%;

    label,
    select {
      margin-bottom: 0;
    }
  }

  .title-keyline::after {
    background-color: $orange-400;
  }

  .card {
    padding: rem-calc(35 0 40);
    width: 100%;
    border-bottom: rem-calc(1) solid $grey-100;

    &:first-of-type {
      border-top: rem-calc(1) solid $grey-100;
    }

    .category {
      color: $orange-700;
      font-size: rem-calc(18);
      font-weight: bold;
      margin-bottom: rem-calc(12);

      a {
        color: inherit;
        font-weight: inherit;
      }
    }

    @include author_avatar(66);

    img.avatar {
      border-radius: 50%;
      width: rem-calc(66);
      margin-right: rem-calc(30);
    }

    .card-section {
      h4 {
        font-weight: bold;
        font-size: rem-calc(37);
        color: $grey-900;
        margin-bottom: rem-calc(15);

        @at-root :lang(ja) & {
          font-size: rem-calc(26);
        }
      }

      p {
        margin-bottom: rem-calc(15);
      }

      &.author {
        .cell {
          margin: 0;
        }

        p {
          text-align: left;

          &.small {
            position: relative;
            margin-bottom: 0;
            min-height: auto;
            padding-right: rem-calc(5);
          }
        }
      }
    }
  }

  .more-articles {
    padding: rem-calc(56) 0 0;

    .fil-icon-arrow-r-light {
      &::after {
        margin-left: rem-calc(8);
        font-weight: bold;
      }
    }
  }
}

@include breakpoint(small only) {
  .fil-article-list {
    .card {
      .card-section {
        h4 {
          font-size: rem-calc(26);
        }

        h4,
        p {
          min-height: auto;
        }

        p {
          margin-bottom: rem-calc(24);
        }

        a,
        .author__name {
          display: block;
          margin-top: rem-calc(5);
          text-align: left;
        }

        img {
          margin-right: rem-calc(20);
        }
      }
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.paginator__nav {
  $button-highlight: $blue-600;

  margin: rem-calc(32) 0;
  user-select: none;
  text-align: center;

  .paginator__nav-button {
    border-radius: 50%;
    border: rem-calc(1) solid $grey-100;
    color: $button-highlight;
    cursor: pointer;
    display: inline-block;
    height: rem-calc(42);
    margin: 0 rem-calc(4);
    width: rem-calc(42);

    @include breakpoint(medium) {
      margin: 0 rem-calc(8);
    }

    &:hover {
      background-color: $grey-25;
      color: $black;
    }

    &::before {
      display: none;
    }

    &.paginator__nav-button--current {
      background-color: $button-highlight;
      border-color: $button-highlight;
      color: $white;
    }
  }

  .paginator__nav-button-inner {
    float: left;
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.paginator__per-page {
  text-align: center;

  label {
    display: inline-block;
    margin-right: rem-calc(16);
  }

  select {
    display: inline-block;
    width: auto;

    @include form-select;
  }
}

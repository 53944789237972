//Enforcing the base font size for rems, !important to overwrite tools
@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

html {
  font-size: rem-calc(16) !important;
}

h2 {
  @include breakpoint(medium down) {
    font-size: rem-calc(34);
  }
}

// h6,
// p {
//   margin-bottom: rem-calc(40);
// }

// ul,
// ol {
//   margin-bottom: rem-calc(40);
//   margin-left: rem-calc(28);

//   li {
//     &::before {
//       left: rem-calc(-28);
//     }
//   }

//   ul,
//   ol {
//     margin-left: rem-calc(62);
//   }

//   ul {
//     li {
//       &::before {
//         background-color: $white;
//         border: rem-calc(1) solid $blue-500;
//       }
//     }
//   }
// }

// ol {
//   li {
//     &::before {
//       color: $grey-800;
//     }
//   }
// }

a {
  &:hover {
    color: $grey-900;
    text-decoration: none !important;
  }
}

@include breakpoint(small only) {
  // h2 {
  //   margin-bottom: rem-calc(40);
  // }

  // h6,
  // p {
  //   margin-bottom: rem-calc(40);
  // }

  ul,
  ol {
    margin-left: rem-calc(24);

    li {
      &::before {
        left: rem-calc(-24);
      }
    }

    ul,
    ol {
      margin-left: rem-calc(25);
      margin-top: rem-calc(12);
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;
@use '@fil-global/gds-components/scss/utils/utils' as utils;
@use '@fil-global/gds-components/scss/list/list' as fil-list;

.fil-accordion {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);
  margin-bottom: 0 !important;

  @include breakpoint(medium) {
    margin-bottom: rem-calc(40);
  }

  .fil-title-paragraph {
    ul {
      @include fil-list.fil-unordered-list;
    }

    ol {
      @include fil-list.fil-ordered-list;
    }
  }

  .fil-enhanced-list {
    border-top: none;
    border-bottom: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  .fil-table-wrapper {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .fil-video {
    .kv-viewer {
      .kv-thumbnail {
        width: 100% !important;
        height: inherit !important;
      }

      div {
        width: 100% !important;
        height: inherit !important;
      }

      .kv-packshot-button {
        width: 100% !important;
        height: inherit !important;
      }

      .kv-packshot-button + .kv-play-icon img,
      .kv-packshot-button .kv-play-icon img {
        max-width: rem-calc(72);
      }
    }

    .medium-10 {
      width: 100%;
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.client-type {
  margin-bottom: 0;

  .client-option {
    border-bottom: rem-calc(1) solid $grey-100;
    font-size: rem-calc(28);
    padding: rem-calc(12 0 24);

    @include breakpoint(medium) {
      font-size: rem-calc(37);
    }

    &:last-of-type {
      border: none;
      margin-bottom: 0;
    }
  }
}

@include breakpoint(small only) {
  .client-option {
    display: block;
    margin-bottom: rem-calc(16);

    .image-wrapper {
      float: left;
    }

    svg {
      width: rem-calc(88);
      height: auto;
    }

    span {
      float: left;
      margin: rem-calc(32 0 0 20);
    }
  }
}

@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

// For japan only
.fil-header-jp {
  position: relative;
  width: 100%;
  z-index: 99;

  .nav-bar {
    position: relative;
    background-color: $white;
    margin: 0 0 0 rem-calc(40);
    border-bottom: rem-calc(1) solid $grey-100;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -50vw;
      height: calc(100% + #{rem-calc(1)});
      width: 50vw;
      background-color: $white;
      border-bottom: rem-calc(1) solid $grey-100;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 100%;
      height: calc(100% + #{rem-calc(1)});
      width: 50vw;
      background-color: $white;
      border-bottom: rem-calc(1) solid $grey-100;
    }

    .logo {
      margin: rem-calc(6) 0 0;
    }

    .login {
      display: block;
    }

    .logout,
    .fil-multi-dropdown {
      display: none;
    }

    .fil-icon-login {
      background: url($login-icon-black) no-repeat 50%;
      display: inline-block;
      width: rem-calc(22);
      height: rem-calc(34);
      vertical-align: middle;
      margin-right: rem-calc(8);
    }

    .fil-icon-logout {
      background: url($logout-icon-black) no-repeat 50%;
      display: inline-block;
      width: rem-calc(70);
      height: rem-calc(22);
      vertical-align: middle;
      margin: rem-calc(6) auto rem-calc(10) auto !important;
    }

    &.logged-in {
      .login {
        display: none;
      }

      .logout,
      .fil-multi-dropdown {
        display: block;
      }
    }

    .main-menu {
      @include breakpoint(large) {
        height: rem-calc(115);

        .cell {
          &.tier-one-wrapper-last.no-search {
            .tier-one {
              padding-right: 0;
              margin-right: rem-calc(32);
            }
          }

          .topsearch {
            .toggle-search {
              padding-right: 0 !important;
            }
          }
        }
      }
    }

    .menu {
      z-index: 99;

      a.tier-one {
        display: block;
        z-index: 99;
        position: relative;
        font-size: rem-calc(18);
        color: $black-dim;
        padding: rem-calc(45.5 15 45.5);
        margin: 0 rem-calc(10);
        text-decoration: none;

        @include breakpoint(large only) {
          padding: rem-calc(45.5 8);
        }

        &::before {
          content: '';
          background-color: $blue-500;
          display: none;
          position: absolute;
          top: rem-calc(77);
          width: calc(100% - (#{rem-calc(15)} * 2) - #{rem-calc(19)});
          height: rem-calc(3.2);

          @include breakpoint(large only) {
            width: calc(100% - (#{rem-calc(15)} * 2) - #{rem-calc(9)});
          }
        }

        &:hover {
          color: $blue-500;
        }

        &.tier-one-mypage {
          display: none;
        }

        span {
          font-size: rem-calc(13);
          color: $grey-200;
          margin-left: rem-calc(4.8);
        }

        &.active-megamenu {
          color: $blue-500;

          &::before {
            display: block;
          }

          span {
            display: inline-block;
            -moz-transform: scaleY(-1);
            -o-transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
            filter: FlipV;
            -ms-filter: 'FlipV';
          }

          + .megamenu {
            height: auto;
            max-height: rem-calc(999);
            overflow-y: visible;
            overflow-x: visible;
            opacity: 1;
            border-top: rem-calc(1) solid $grey-100;
            padding: 0 rem-calc(32 30) 0;
            margin: 0 auto;
            max-width: 100%;

            .tier-two-wrapper {
              &.medium-12 > div > div:first-child {
                padding-left: 0;
              }
            }

            .category {
              transform: none;
              opacity: 1;

              &:nth-of-type(1) {
                transition-delay: 0.3s;
              }

              &:nth-of-type(2) {
                transition-delay: 0.4s;
              }

              &:nth-of-type(3) {
                transition-delay: 0.5s;
              }

              &:nth-of-type(4) {
                transition-delay: 0.6s;
              }

              &:nth-of-type(5) {
                transition-delay: 0.7;
              }
            }

            + .backdrop {
              height: 100vh;
              background-color: rgba($black, 0.7);

              + .close-megamenu {
                display: block;
              }
            }
          }
        }

        &.ancestor {
          color: $blue-500;

          @include breakpoint(medium down) {
            color: $black-dim;
          }

          &::before {
            display: block;
          }
        }

        &.no-children {
          &::before {
            width: calc(100% - (#{rem-calc(15)} * 2));
          }
        }

        &.active-ancestor {
          color: $black-dim !important;

          &::before {
            background-color: $grey-100 !important;

            @include breakpoint(medium down) {
              background-color: $blue-500 !important;
            }
          }
        }
      }

      .megamenu {
        display: block;
        opacity: 0;
        max-height: 0;
        height: 0;
        z-index: 99;
        overflow-y: hidden;
        overflow-x: hidden;
        background-color: $grey-25;
        border-top: none;
        position: absolute;
        top: calc(100% - #{rem-calc(1)});
        left: 0;
        right: 0;
        width: 100%;
        transition: opacity 0.3s linear, max-height 0.8s ease;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: rem-calc(-1);
          width: 50vw;
          //Do NOT use rem-calc here, it doesn't work inside calc()
          height: calc(100% + #{rem-calc(1)});
          background-color: $grey-25 !important;
          border-top: rem-calc(1) solid $grey-100;
        }

        &::before {
          left: -50vw;
        }

        &::after {
          right: -50vw;
        }

        .cell .cell {
          padding: rem-calc(10 10 0);
        }

        .meta {
          padding: rem-calc(10 10) 0 0;
          border-right: rem-calc(1) solid $grey-100;

          &.no-tier-three {
            border-right: none;
          }

          a {
            font-weight: bold;
            text-decoration: underline;
          }
        }

        h5 {
          font-weight: bold;
          margin-top: rem-calc(30);
          text-decoration: underline;
        }

        h6 {
          font-size: rem-calc(18);
          margin-bottom: rem-calc(0);
          min-height: rem-calc(26);
        }

        hr {
          margin: rem-calc(17 0 19);
        }

        a {
          margin-bottom: rem-calc(16);
          font-size: rem-calc(18);
          display: block;

          &.megamenu-title {
            display: inline-block;
            margin-bottom: 0;

            @include breakpoint(medium down) {
              display: block;
            }
          }

          &:focus {
            text-decoration: none;
          }

          &.selected {
            color: $black-dim;
            font-weight: bold !important;
          }
        }

        .button {
          display: inline-block;
          width: auto;
        }

        &:hover {
          + .backdrop {
            height: 100vh;
            background-color: rgba($black, 0.7);
          }
        }

        .category {
          opacity: 0;
          transform: translateY(-5%);
          transition: all 0.1s ease-in;
          transition-delay: 0s;
        }

        .tier-two {
          position: relative;

          .category {
            .tier-two-menu,
            a:first-of-type {
              text-decoration: underline;
              font-weight: bold;
            }
          }

          .vertical-menu-wrapper {
            flex: 1 1 0;
            flex-direction: column;
          }
        }

        &-banner-wrapper {
          width: rem-calc(230);
          height: rem-calc(225);
          margin-top: 0;
          margin-bottom: rem-calc(16);
          padding-top: rem-calc(130);
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100%;

          &--label {
            display: flex;
            place-items: center;
            padding: rem-calc(20);
            height: rem-calc(100);
            font-size: rem-calc(16);
            background: $blue-600;

            .link {
              line-height: rem-calc(24);
              color: $white;
              margin-bottom: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;

              /* autoprefixer: off */
              -webkit-box-orient: vertical;

              /* autoprefixer: on */
            }
          }

          &.no-banner {
            height: rem-calc(130);
            padding-top: 0;

            a {
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      .close-megamenu {
        position: absolute;
        display: none;
        z-index: 999;
        margin-top: rem-calc(30);
        right: rem-calc(32);
        color: $blue-500;

        &:hover {
          cursor: pointer;
          color: $grey-dark-dim;
        }

        &::after {
          position: relative;
          top: rem-calc(2);
          font-weight: bold;
        }
      }

      .backdrop {
        content: '';
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        transition: background-color 0.2s ease;
        background-color: rgba($black, 0);
      }
    }
  }

  .logo-container {
    align-items: center;
    display: flex;
  }
}

.tier-three-bar-wrapper {
  background-color: $grey-25;
  width: 100%;
  position: relative;
  margin: 0 auto;

  .tier-three-bar {
    margin-left: rem-calc(40);
    padding: 0;

    .drop-down-menu-container {
      word-break: break-all;
      overflow: hidden;
      width: 90%;
      max-height: rem-calc(67);

      > a {
        height: rem-calc(37);
      }
    }

    .parent {
      border-right: rem-calc(1) solid $grey-100;
      padding-left: 0;
    }

    a {
      margin: rem-calc(15) 0;
      padding: rem-calc(7 12);
      display: inline-block;
      font-size: rem-calc(15);

      &:nth-of-type(1) {
        padding-right: rem-calc(24);
        font-weight: bold;
      }

      &:nth-of-type(2) {
        padding-left: rem-calc(24);
      }

      &.selected {
        color: $black-dim;
        font-weight: bold;
      }
    }

    .overflow-link {
      float: right;
      padding-right: 0;
      position: absolute;
      right: 0;
      top: 0;
      display: none;

      a {
        font-weight: normal;
      }

      &.active {
        span {
          display: inline-block;
          -moz-transform: scaleY(-1);
          -o-transform: scaleY(-1);
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
          filter: FlipV;
          -ms-filter: 'FlipV';
        }

        .overflow-dropdown {
          display: block;
        }
      }

      .overflow-dropdown {
        position: absolute;
        width: rem-calc(260);
        z-index: 999;
        display: none;
        top: 100%;
        right: 0;
        background-color: $grey-25;

        a {
          display: block;
          margin: 0;
          padding: rem-calc(25);
        }
      }
    }
  }
}

.tier-three-nav-block {
  height: rem-calc(65);
  width: rem-calc(1);
}

@include breakpoint(medium down) {
  .tier-three-nav-block {
    display: none;
  }

  .fil-header-jp {
    .darkdrop {
      content: '';
      z-index: -1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      transition: background-color 0.2s ease;
      background-color: transparent;
      cursor: pointer;
    }

    &.is-open {
      bottom: 0;
      overflow-y: hidden;
      overflow-x: hidden;

      .darkdrop {
        height: 100vh;
        background-color: $black;
        opacity: 0.6;
      }
    }

    &.is-sub-open {
      overflow: hidden;
    }

    .nav-bar {
      margin: 0;
      border-bottom: none;

      .logo-container {
        border-bottom: rem-calc(1) solid $grey-100;
        padding: 0 !important;
        height: rem-calc(76);

        .logo {
          max-width: rem-calc(40);
          margin: rem-calc(20 18);
        }
      }

      .main-menu,
      .account-option-mobile-menu {
        transition: all 0.3s ease-in-out;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
      }

      .menu-toggle {
        cursor: pointer;
        padding: rem-calc(13 8);
        border-left: rem-calc(1) solid $grey-100;
        border-bottom: rem-calc(1) solid $grey-100;
        text-align: center;
        min-width: rem-calc(80);
        width: auto;
        height: rem-calc(76);

        &.is-open {
          .menu-closed {
            display: none;
          }

          .menu-open {
            display: block;

            .fil-icon {
              margin: rem-calc(6) auto rem-calc(6) !important;

              &::after {
                font-size: rem-calc(16) !important;
              }
            }
          }

          + .main-menu {
            max-height: rem-calc(9999);
            overflow: visible;
            opacity: 1;
          }
        }

        &.fil-multi-dropdown.is-open ~ .account-option-mobile-menu {
          max-height: rem-calc(9999);
          overflow: visible;
          opacity: 1;
        }

        span {
          display: block;
          width: 100%;

          + span {
            margin-top: rem-calc(-5);
            font-size: rem-calc(14);
          }

          &.fil-icon {
            margin-bottom: rem-calc(4);

            &::after {
              font-size: rem-calc(24);
            }
          }
        }

        .menu-open {
          display: none;
        }
      }

      .menu {
        .cell {
          width: 100%;

          a.tier-one {
            width: 100%;
            position: relative;
            margin: 0;
            padding: rem-calc(20);
            border-bottom: rem-calc(1) solid $grey-dim-100;
            font-weight: bold;
            z-index: 9;

            &::before {
              content: '';
              position: absolute;
              display: block;
              margin-left: 0;
              left: 0;
              top: 0;
              bottom: 0;
              width: rem-calc(3);
              height: 100%;
            }

            span {
              float: right;
              color: $blue-500;
            }

            &:hover {
              + .megamenu {
                opacity: 0;
                border-top: none;
                max-height: 0;
              }
            }

            &.active-megamenu {
              background-color: $grey-25;
              color: $blue-500;

              + .megamenu {
                max-height: rem-calc(999);
                opacity: 1;
                border-top: rem-calc(1) solid $grey-100;
                border-bottom: rem-calc(1) solid $grey-100;
                padding: 0;
              }
            }
          }
        }

        .megamenu {
          background-color: $grey-25;
          border-top: none;
          top: 0;
          position: relative;
          padding: 0;

          a {
            margin-bottom: 0;
            padding: rem-calc(20);
            text-decoration: none;
            border-bottom: rem-calc(1) solid $grey-light-dim;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              display: block;
              margin-left: 0;
              left: 0;
              top: 0;
              bottom: 0;
              background-color: $blue-500;
              width: rem-calc(3);
              height: 100%;
            }

            &.tier-three-link::before {
              background-color: transparent;
            }
          }

          h5 {
            font-size: rem-calc(18);
            text-decoration: none;
            margin: 0;
          }

          .cell {
            .cell {
              padding: 0;
            }
          }

          .meta {
            border-right: none;
            padding: 0;

            a {
              text-decoration: none;
              font-weight: normal;
            }
          }

          .tier-two-wrapper {
            padding: 0;

            .tier-two {
              .category {
                transition-delay: 0s !important;

                .category-header {
                  &.selected {
                    color: $black-dim;
                    font-weight: bold !important;
                  }

                  color: $blue-500;
                  padding: rem-calc(20);
                  font-weight: normal;
                  border-bottom: rem-calc(1) solid $grey-100;
                  text-decoration: none;

                  .fil-icon {
                    &::after {
                      font-weight: bold;
                      margin-left: rem-calc(6);
                      position: absolute;
                      right: rem-calc(20);
                    }
                  }
                }

                .category-body {
                  background-color: $grey-25;
                  z-index: -1;
                  position: fixed;
                  top: rem-calc(76);
                  left: 0;
                  width: 100%;
                  height: calc(100vh - #{rem-calc(76)});
                  padding-bottom: rem-calc(76);
                  transform: translateX(100vw);
                  transition: all 0.2s ease-in;
                  overflow-y: auto;

                  &-inner {
                    height: 100%;
                    overflow: scroll;
                  }

                  &.active {
                    z-index: 99;
                    transform: translateX(0);
                    transition: all 0.2s ease-out;
                  }

                  a,
                  .category-header {
                    margin: 0;
                    font-weight: normal;
                    padding: rem-calc(20);
                    text-decoration: none;
                    border-bottom: rem-calc(1) solid $grey-100;
                  }

                  .category-body-return {
                    width: 100%;
                    padding: rem-calc(20);
                    margin: 0;
                    background-color: $white;

                    &::before {
                      content: '';
                      position: absolute;
                      left: 0;
                      top: 0;
                      bottom: 0;
                      width: rem-calc(3);
                      height: 100%;
                    }
                  }

                  .fil-icon-arrow-l-light {
                    color: $blue-500;
                    font-weight: bold;
                    top: rem-calc(1);
                    position: relative;
                    margin-right: rem-calc(6);
                  }
                }
              }
            }
          }
        }

        .close-megamenu {
          display: none !important;
        }
      }

      .login {
        &.login-disabled {
          display: none !important;
        }
      }

      .account-option-mobile-menu {
        .menu .megamenu .meta a {
          font-weight: normal;
        }

        a.tier-one {
          &::before {
            width: 0 !important;
          }
        }

        .menu .megamenu a::before {
          background: transparent !important;
        }
      }
    }
  }

  .tier-three-bar-wrapper {
    display: none;
  }

  .header-wrapper {
    + .bg-grey-50 {
      margin-top: rem-calc(36) !important;
    }
  }
}

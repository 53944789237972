@use '@fil-global/gds-core/scss/utils/bootstrap' as *;

.fil-content-switcher {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  &-section {
    &--above,
    &--below {
      .fil-text-container {
        .cell {
          @include breakpoint(medium) {
            @include xy-cell-size(10, $gutter-type: none);
          }
        }

        padding-top: 0;
        padding-bottom: 0;
      }

      .fil-text-body-container {
        &.small {
          @include typography(body-xs);
        }
      }
    }

    &--above {
      margin-bottom: rem-calc(19);
    }

    &--below {
      margin-top: rem-calc(30);

      &.no-children {
        margin-top: 0;
      }

      @include breakpoint(medium) {
        margin-top: rem-calc(64);
      }
    }
  }
}

.fil-dropdown {

  &-options {
    display: none;

    &.active {
      display: block;
    }

    .fil-text-container {
      .cell {
        @include breakpoint(medium) {
          @include xy-cell-size(10, $gutter-type: none);
        }
      }

      padding-top: 0;
      padding-bottom: 0;
    }

    fil-accordion {
      p {
        img {
          width: 100%;
        }
      }
    }
  }
}

.fil-checked-buttons {
  .fil-dropdown-filtered-content {
    margin-top: rem-calc(16);

    .fil-accordion {
      padding-top: rem-calc(24);
      padding-bottom: rem-calc(24);
    }
  }
}
